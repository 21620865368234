<template>
  <v-card>
    <TechnologicalDevelopmentsPgHeader></TechnologicalDevelopmentsPgHeader>
    <section class="form-full-width">
      <FormTechnologicalDevelopmentsPg :loading="loading" :getTechnologicalDevelopmentsPg="getTechnologicalDevelopmentsPg" @beforeUpdate="updateTechnologicalDevelopmentsPg"></FormTechnologicalDevelopmentsPg>
    </section>
  </v-card>
</template>

<script>
import FormTechnologicalDevelopmentsPg from '@/components/forms/FormTechnologicalDevelopmentsPg.vue'
import TechnologicalDevelopmentsPgHeader from './TechnologicalDevelopmentsPgHeader.vue'
import useTechnologicalDevelopmentsPg from '@/composables/useTechnologicalDevelopmentsPg'

export default {
  components: {
    FormTechnologicalDevelopmentsPg,
    TechnologicalDevelopmentsPgHeader,
  },
  setup() {
    // Composition API
    const {
      technologicalDevelopmentsPg,
      loading,
      // methods
      getTechnologicalDevelopmentsPg,
      updateTechnologicalDevelopmentsPg,
    } = useTechnologicalDevelopmentsPg()

    return {
      technologicalDevelopmentsPg,
      loading,
      // methods
      getTechnologicalDevelopmentsPg,
      updateTechnologicalDevelopmentsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
