import {ref} from '@vue/composition-api';
import store from '@/store/index';

const useTechnologicalDevelopmentsPg = () => {
  // base data
  const technologicalDevelopmentsPg = ref (null);
  const loading = ref (false);

  // methods
  const getTechnologicalDevelopmentsPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchTechnologicalDevelopmentsPg');
    loading.value = false;
    if (status != 200) return;
    technologicalDevelopmentsPg.value = data;
    return data;
  };

  const updateTechnologicalDevelopmentsPg = async technologicalDevelopmentsPg => {
    loading.value = true;
    const {data, status} = await store.dispatch (
      'updateTechnologicalDevelopmentsPg',
      technologicalDevelopmentsPg
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    technologicalDevelopmentsPg,
    loading,
    // methods
    getTechnologicalDevelopmentsPg,
    updateTechnologicalDevelopmentsPg,
  };
};

export default useTechnologicalDevelopmentsPg;
